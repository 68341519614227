import React from "react";
import {Container} from "react-bootstrap";
import {UserListing} from "../../components/user/Listing";
import {BrandListing} from "../../components/brand/Listing";
import {RoleListing} from "../../components/role/Listing";

export const SettingsOverview = () => {
  return (
    <Container>
      <UserListing />
      <RoleListing />
      <BrandListing />
    </Container>
  )
}
