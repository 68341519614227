import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import React, {useState} from "react";
import {Brand} from "../../generated/graphql";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MUT_BRAND_REMOVE = gql`
  mutation removeBrand($id: Float!) {
    removeBrand(id: $id) {
      name
    }
  }
`;

export const BrandRemove = (
  listing: {
    brand: Partial<Brand>;
    refetch: any;
  },
) => {
  const [removeBrand] = useMutation(MUT_BRAND_REMOVE, {
    onCompleted: () => {
      toast.success("Marke entfernt.");
      listing.refetch();
    },
    onError: () => {
      toast.error("Fehler beim Entfernen der Marke!");
      listing.refetch();
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="me-2" variant="danger" onClick={handleShow}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Marke entfernen</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Marke wirklich entfernen?
        </ModalBody>
        <ModalFooter>
          <Button className="me-2" variant="danger" onClick={() => removeBrand({
            variables: { id: listing.brand.id }
          })}>
            <FontAwesomeIcon icon={faTrash} /> Entfernen
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
