import React, {FC} from "react"
import {useLocalStorage} from "../../hooks/useLocalStorage";
import { jwtDecode, JwtPayload } from "jwt-decode";

type AuthContextProps = {
  jwt: string,
  setJwt: (value: (((val: string) => string) | string)) => {}
}

const AuthContext = React.createContext<AuthContextProps>({
  jwt: "",
  setJwt: (value: (((val: string) => string) | string)) => {
  }
} as AuthContextProps);

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export enum Permissions {
  proofCreate = "permissionProofCreate",
  proofUpdate = "permissionProofUpdate",
  proofDelete = "permissionProofDelete"
}

export interface JwtPayloadCuprint extends JwtPayload {
  isAdmin: boolean,
  permissions: [
    Permissions
  ]
}

const AuthContextProvider:FC<AuthContextProviderProps> = ({ children}) => {
  const [jwt, setJwt] = useLocalStorage<string>("jwt", "");
  const value = {jwt, setJwt};

  // delete expired token
  if(jwt) {
    const decoded: JwtPayloadCuprint = jwtDecode(jwt.toString());
    const now = new Date();
    if(decoded.exp && (decoded.exp*1000 < now.getTime())) {
      setJwt("")
    }
  }

  return (
    <AuthContext.Provider value={value as AuthContextProps}>
      {children}
    </AuthContext.Provider>
  )
}

export {AuthContextProvider, AuthContext}