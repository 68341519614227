import React, {useContext, useState} from "react";
import {Alert, Button, Container, FloatingLabel, FormControl} from "react-bootstrap";
import {Form, Formik} from "formik";
import axios from "axios";
import {AuthContext} from "../components/auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {PROOF_LIST} from "../constants/routes";

const initialValues: { username: string; password: string; } = {
  username: "",
  password: ""
}

export const Login = () => {
  const {setJwt} = useContext(AuthContext);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const AlertLoginError = () => {
    return (
      <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
        Fehler beim Login!
      </Alert>
    );
  }

  return (
    <Container>
      <div style={{maxWidth: 350}}  className="w-100 m-auto">
      <h1 className="text-center">Login</h1>
      {showError && <AlertLoginError />}
      <Formik enableReinitialize initialValues={initialValues} onSubmit={(values) => {
        axios
          .post(`${process.env.REACT_APP_GRAPHQL_SERVER}/auth/login`, values)
          .then((res) => {
            setJwt(res.data.access_token);
            navigate(PROOF_LIST);
          })
          .catch(() => {
            setShowError(true);
          })
      }}
      >
        {(form) => (
          <Form>
            <FloatingLabel
              controlId="username"
              label="E-Mail"
              className="mb-3"
            >
              <FormControl
                type="text"
                name="username"
                size="sm"
                required
                defaultValue={form.values.username}
                onChange={form.handleChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="password" label="Passwort">
              <FormControl
                type="password"
                name="password"
                size="sm"
                required
                defaultValue={form.values.password}
                onChange={form.handleChange}
              />
            </FloatingLabel>

            <Button type="submit" className="w-100 mt-3 py-3">
              Login
            </Button>
          </Form>
        )}
      </Formik>
      </div>
    </Container>
  )
}
