import React, {useContext} from "react";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {LOGIN} from "../../constants/routes";

export const LogoutNavButton = () => {
  const {setJwt} = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    setJwt("");
    navigate(LOGIN);
  }

  return (
    <Nav.Item>
      <Nav.Link onClick={logout}>Logout</Nav.Link>
    </Nav.Item>
  )
}
