import React, {useContext} from "react";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import * as ROUTES from "./constants/routes";
import {ProofListing} from "./pages/proof/Listing";
import {ProofDetail} from "./pages/proof/Detail";
import {Navigation} from "./components/interface/Navigation";
import {Login} from "./pages/Login";
import {SettingsOverview} from "./pages/settings/Overview";
import {PrivateRoute} from "./components/auth/PrivateRoute";
import {ProtectedRoute} from "./components/auth/ProtectedRoute";
import {AuthContext} from "./components/auth/AuthContext";
import {setContext} from "@apollo/client/link/context";

function App() {
  const {jwt} = useContext(AuthContext);

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_SERVER}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    }),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ToastContainer />
        <Navigation />
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.PROOF_LIST} element={
            <PrivateRoute>
              <ProofListing/>
            </PrivateRoute>
          } />
          <Route path={ROUTES.PROOF_DETAIL} element={
            <PrivateRoute>
              <ProofDetail />
            </PrivateRoute>
          } />
          <Route path={ROUTES.SETTINGS_OVERVIEW} element={
            <PrivateRoute>
              <ProtectedRoute>
                <SettingsOverview />
              </ProtectedRoute>
            </PrivateRoute>
          } />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
