import {useContext} from "react";
import {AuthContext, JwtPayloadCuprint, Permissions} from "./AuthContext";
import { jwtDecode } from "jwt-decode";

export const ProtectedComponent = (
  { children, permission }: { children: JSX.Element; permission?: Permissions }
) => {
  const {jwt} = useContext(AuthContext);
  const decoded: JwtPayloadCuprint = jwtDecode(jwt.toString());

  if (decoded.isAdmin) {
    return children;
  }

  if (permission && decoded.permissions.filter(
    (item) => item===permission
  )[0]) {
    return children;
  }

  return null;
};