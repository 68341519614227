import React, {useState} from "react";
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {Role} from "../../generated/graphql";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

const MUT_ROLE_UPDATE = gql`
  mutation updateRole($updateRoleInput: UpdateRoleInput!) {
    updateRole(updateRoleInput: $updateRoleInput) {
      id
    }
  }
`;

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required(),
  permissionProofCreate: Yup.boolean().required(),
  permissionProofUpdate: Yup.boolean().required(),
  permissionProofDelete: Yup.boolean().required(),
});

export const RoleUpdate = (
  listing: {
    role: Partial<Role>
    refetch: any;
  },
) => {

  const [updateRole] = useMutation(MUT_ROLE_UPDATE, {
    onCompleted: () => {
      toast.success("Rolle bearbeitet.");
      handleClose();
      listing.refetch();
    },
    onError: (error) => {
      console.log(error)
      toast.error("Fehler beim Bearbeiten der Rolle!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning" onClick={handleShow} className="me-2">
        <FontAwesomeIcon icon={faPen} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Rolle bearbeiten</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Formik
            initialValues={{
              id: listing.role.id,
              name: listing.role.name,
              permissionProofCreate: listing.role.permissionProofCreate,
              permissionProofUpdate: listing.role.permissionProofUpdate,
              permissionProofDelete: listing.role.permissionProofDelete,
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              updateRole({
                variables: { updateRoleInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col>
                      <FormGroup className="mb-3" controlId="name">
                        <FormLabel>Name*</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          required
                          defaultValue={form.values.name}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <h5>Druckfreigabe:</h5>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofCreate">
                        <FormLabel>Erstellen</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofCreate"
                          checked={form.values.permissionProofCreate}
                          onChange={e => form.setFieldValue('permissionProofCreate', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofUpdate">
                        <FormLabel>Ändern</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofUpdate"
                          checked={form.values.permissionProofUpdate}
                          onChange={e => form.setFieldValue('permissionProofUpdate', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofDelete">
                        <FormLabel>Löschen</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofDelete"
                          checked={form.values.permissionProofDelete}
                          onChange={e => form.setFieldValue('permissionProofDelete', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
