import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Badge, Card, Nav, Table} from "react-bootstrap";
import {RoleListQuery} from "../../generated/graphql";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {RoleCreate} from "./Create";
import {RoleUpdate} from "./Update";

export const QRY_ROLE_LIST = gql`
  query RoleList {
    roles {
      id
      name
      permissionProofCreate
      permissionProofUpdate
      permissionProofDelete
      createdAt
      updatedAt
    }
  }
`;

export const RoleListing = () => {
  const {data, refetch} = useQuery<RoleListQuery>(QRY_ROLE_LIST);
  return (
    <Card className="p-3 my-3">
      <h2>Rollen</h2>
      <Nav className="my-2">
        <RoleCreate refetch={refetch} />
      </Nav>
      <Table striped bordered hover responsive="lg" className="align-middle">
        <thead>
        <tr>
          <th></th>
          <th>Bezeichnung</th>
          <th>Berechtigung</th>
          <th>Datum</th>
        </tr>
        </thead>
        <tbody>
        {data &&
          data.roles &&
          data.roles.map((role, key) => (
            <tr key={key}>
              <td>
                <RoleUpdate role={role} refetch={refetch} />
              </td>
              <td>{role.name}</td>
              <td>
                Druckfreigabe:
                {role.permissionProofCreate ? <Badge className="mx-1" bg="success">erstellen</Badge> : <Badge className="mx-1" bg="secondary">erstellen</Badge>}
                {role.permissionProofUpdate ? <Badge className="mx-1" bg="success">ändern</Badge> : <Badge className="mx-1" bg="secondary">ändern</Badge>}
                {role.permissionProofDelete ? <Badge className="mx-1" bg="success">löschen</Badge> : <Badge className="mx-1" bg="secondary">löschen</Badge>}
              </td>
              <td>
                <span className="small" style={{cursor:"help"}} title={new Date(role.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPlus} /> {new Date(role.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span><br />
                <span className="small" style={{cursor:"help"}} title={new Date(role.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPen} /> {new Date(role.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
