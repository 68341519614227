import React, {useState} from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {Brand} from "../../generated/graphql";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MUT_BRAND_UPDATE = gql`
  mutation updateBrand($updateBrandInput: UpdateBrandInput!) {
    updateBrand(updateBrandInput: $updateBrandInput) {
      uuid
    }
  }
`;

export const BrandUpdate = (
  listing: {
    brand: Partial<Brand>
    refetch: any;
  },
) => {

  const [updateBrand] = useMutation(MUT_BRAND_UPDATE, {
    onCompleted: () => {
      toast.success("Marke bearbeitet.");
      handleClose();
      listing.refetch();
    },
    onError: (error) => {
      console.log(error)
      toast.error("Fehler beim Bearbeiten der Marke!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning" onClick={handleShow} className="me-2">
        <FontAwesomeIcon icon={faPen} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Marke bearbeiten</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Formik
            initialValues={listing.brand}
            onSubmit={(formdata, { setSubmitting }) =>
              updateBrand({
                variables: { updateBrandInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="name">
                        <FormLabel>Name*</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          required
                          defaultValue={form.values.name}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="logoUrl">
                        <FormLabel>Logo URL</FormLabel>
                        <FormControl
                          type="text"
                          name="logoUrl"
                          defaultValue={form.values.logoUrl || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="supportPhone">
                        <FormLabel>Support Telefon</FormLabel>
                        <FormControl
                          type="text"
                          name="supportPhone"
                          defaultValue={form.values.supportPhone || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="supportMail">
                        <FormLabel>Support Email</FormLabel>
                        <FormControl
                          type="text"
                          name="supportMail"
                          defaultValue={form.values.supportMail || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
