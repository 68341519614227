import React, {useContext} from "react";
import {
  Navigate,
  useLocation
} from 'react-router-dom';
import {LOGIN} from "../../constants/routes";
import {AuthContext} from "./AuthContext";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const {jwt} = useContext(AuthContext);
  const location = useLocation();

  if (!jwt) {
    return <Navigate to={LOGIN} replace state={{ from: location }} />;
  }

  return children;
};
