import React, {useState} from "react";
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  InputGroup,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {Role, UpdateUserInput} from "../../generated/graphql";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

const MUT_USER_UPDATE = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
    }
  }
`;

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  username: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  role: Yup.object().notRequired().nullable(),
  isActive: Yup.boolean().required(),
  isAdmin: Yup.boolean().required(),
});

export const UserUpdate = (
  listing: {
    user: UpdateUserInput;
    roles: Partial<Role>[];
    refetch: any;
  },
) => {

  const [updateUser] = useMutation(MUT_USER_UPDATE, {
    onCompleted: () => {
      toast.success("Benutzer bearbeitet.");
      handleClose();
      listing.refetch();
    },
    onError: (error) => {
      console.log(error)
      toast.error("Fehler beim Bearbeiten des Benutzers!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning" onClick={handleShow} className="me-2">
        <FontAwesomeIcon icon={faPen} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Benutzer bearbeiten</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Formik
            initialValues={{
              id: listing.user.id,
              username: listing.user.username,
              firstName: listing.user.firstName,
              lastName: listing.user.lastName,
              role: listing.user.role || null,
              isActive: listing.user.isActive,
              isAdmin: listing.user.isAdmin,
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              updateUser({
                variables: { updateUserInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="username">
                        <FormLabel>E-Mail*</FormLabel>
                        <FormControl
                          type="text"
                          name="username"
                          required
                          defaultValue={form.values.username}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="firstName">
                        <FormLabel>Vorname*</FormLabel>
                        <FormControl
                          type="text"
                          name="firstName"
                          required
                          defaultValue={form.values.firstName}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="lastName">
                        <FormLabel>Nachname*</FormLabel>
                        <FormControl
                          type="text"
                          name="lastName"
                          required
                          defaultValue={form.values.lastName}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="isActive">
                        <FormLabel>Aktiv</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="isActive"
                          checked={form.values.isActive}
                          onChange={e => form.setFieldValue('isActive', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="isAdmin">
                        <FormLabel>Admin</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="isAdmin"
                          checked={form.values.isAdmin}
                          onChange={e => form.setFieldValue('isAdmin', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="role">
                        <FormLabel>Rolle</FormLabel>
                        <InputGroup className="mb-3">
                          <FormSelect
                            name="role"
                            value={form.values.role?.id}
                            onChange={(option) => form.setFieldValue('role', listing.roles.find(role => role.id === parseInt(option.target.value)))}
                          >
                            <option selected={form.values.role === null} disabled>keine</option>
                            {listing?.roles.map((role, key) => (
                              <option key={key} value={role.id}>{role.name}</option>
                            ))}
                          </FormSelect>
                          <Button variant="outline-secondary" onClick={() => form.setFieldValue('role', null)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
