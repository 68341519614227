import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Card, Nav, Table} from "react-bootstrap";
import {BrandListQuery} from "../../generated/graphql";
import {BrandCreate} from "./Create";
import {BrandUpdate} from "./Update";
import {BrandRemove} from "./Remove";

export const QRY_BRAND_LIST = gql`
  query BrandList {
    brands {
      id
      name
      logoUrl
      supportPhone
      supportMail
    }
  }
`;

export const BrandListing = () => {
  const {data, refetch} = useQuery<BrandListQuery>(QRY_BRAND_LIST);
  return (
    <Card className="p-3 my-3">
      <h2>Marken</h2>
      <Nav className="my-2">
        <BrandCreate refetch={refetch} />
      </Nav>
      <Table striped bordered hover responsive="lg" className="align-middle">
        <thead>
        <tr>
          <th></th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        {data &&
          data.brands &&
          data.brands.map((brand, key) => (
            <tr key={key}>
              <td>
                <BrandUpdate brand={brand} refetch={refetch} />
                <BrandRemove brand={brand} refetch={refetch} />
              </td>
              <td>{brand.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
