import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Badge, Card, Nav, Table} from "react-bootstrap";
import {UserListQuery} from "../../generated/graphql";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {UserCreate} from "./Create";
import {UserUpdate} from "./Update";
import {UserUpdatePassword} from "./UpdatePassword";

export const QRY_USER_LIST = gql`
  query UserList {
    roles {
      id
      name
      permissionProofCreate
      permissionProofUpdate
      permissionProofDelete
    }
    users {
      id
      username
      firstName
      lastName
      isActive
      isAdmin
      role {
        id
        name
        permissionProofCreate
        permissionProofUpdate
        permissionProofDelete
      }
      createdAt
      updatedAt
    }
  }
`;

export const UserListing = () => {
  const {data, refetch} = useQuery<UserListQuery>(QRY_USER_LIST);
  return (
    <Card className="p-3 my-3">
      <h2>Benutzer</h2>
      <Nav className="my-2">
        <UserCreate roles={data?.roles} refetch={refetch} />
      </Nav>
      <Table striped bordered hover responsive="lg" className="align-middle">
        <thead>
        <tr>
          <th></th>
          <th>E-Mail</th>
          <th>Name</th>
          <th>Status</th>
          <th>Datum</th>
        </tr>
        </thead>
        <tbody>
        {data &&
          data.users &&
          data.users.map((user, key) => (
            <tr key={key}>
              <td>
                <UserUpdate user={user} roles={data.roles} refetch={refetch} />
                <UserUpdatePassword user={user} refetch={refetch} />
              </td>
              <td>{user.username}</td>
              <td>{user.firstName} {user.lastName}</td>
              <td>
                {user.isActive ? <Badge bg="success">aktiv</Badge> : <Badge bg="secondary">inaktiv</Badge>}<br/>
                {user.isAdmin ? <Badge bg="danger">Admin</Badge> : <Badge bg="warning">Benutzer{user.role && ": " + user.role.name}</Badge>}</td>
              <td>
                <span className="small" style={{cursor:"help"}} title={new Date(user.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPlus} /> {new Date(user.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span><br />
                <span className="small" style={{cursor:"help"}} title={new Date(user.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPen} /> {new Date(user.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
