import React, {useState} from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {faFloppyDisk, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MUT_BRAND_CREATE = gql`
  mutation createBrand($createBrandInput: CreateBrandInput!) {
    createBrand(createBrandInput: $createBrandInput) {
      uuid
    }
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  logoUrl: Yup.string().notRequired(),
  supportPhone: Yup.string().notRequired(),
  supportMail: Yup.string().notRequired(),
});

export const BrandCreate = (listing: {
  refetch: any;
}) => {
  const [createBrand] = useMutation(MUT_BRAND_CREATE, {
    onCompleted: () => {
      toast.success("Marke erstellt.");
      handleClose();
      listing.refetch();
    },
    onError: () => {
      toast.error("Fehler beim Erstellen der Marke!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Marke erstellen</ModalTitle>
        </ModalHeader>
        <Modal.Body>

          <Formik
            initialValues={{
              name: "",
              logoUrl: "",
              supportPhone: "",
              supportMail: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              createBrand({
                variables: { createBrandInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="name">
                        <FormLabel>Name*</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          required
                          defaultValue={form.values.name}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="logoUrl">
                        <FormLabel>Logo URL</FormLabel>
                        <FormControl
                          type="text"
                          name="logoUrl"
                          defaultValue={form.values.logoUrl}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="supportPhone">
                        <FormLabel>Support Telefon</FormLabel>
                        <FormControl
                          type="text"
                          name="supportPhone"
                          defaultValue={form.values.supportPhone}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="supportMail">
                        <FormLabel>Support Email</FormLabel>
                        <FormControl
                          type="text"
                          name="supportMail"
                          defaultValue={form.values.supportMail}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
