import React, {useState} from "react";
import {
  Button,
  Col, FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  InputGroup,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {faFloppyDisk, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Role} from "../../generated/graphql";

const MUT_USER_CREATE = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  role: Yup.object().notRequired().nullable(),
  isActive: Yup.boolean().required(),
  isAdmin: Yup.boolean().required(),
});

export const UserCreate = (listing: {
  roles?: Partial<Role>[];
  refetch: any;
}) => {
  const [createUser] = useMutation(MUT_USER_CREATE, {
    onCompleted: () => {
      toast.success("Benutzer erstellt.");
      handleClose();
      listing.refetch();
    },
    onError: () => {
      toast.error("Fehler beim Erstellen des Benutzers!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Benutzer erstellen</ModalTitle>
        </ModalHeader>
        <Modal.Body>

          <Formik
            initialValues={{
              username: "",
              password: "",
              firstName: "",
              lastName: "",
              role: listing.roles ? listing?.roles[0] : null,
              isActive: true,
              isAdmin: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              createUser({
                variables: { createUserInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="name">
                        <FormLabel>E-Mail*</FormLabel>
                        <FormControl
                          type="text"
                          name="username"
                          required
                          defaultValue={form.values.username}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="logoUrl">
                        <FormLabel>Passwort</FormLabel>
                        <FormControl
                          type="password"
                          name="password"
                          defaultValue={form.values.password}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="firstName">
                        <FormLabel>Vorname</FormLabel>
                        <FormControl
                          type="text"
                          name="firstName"
                          defaultValue={form.values.firstName}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="lastName">
                        <FormLabel>Nachname</FormLabel>
                        <FormControl
                          type="text"
                          name="lastName"
                          defaultValue={form.values.lastName}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-3" controlId="isActive">
                        <FormLabel>Aktiv</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="isActive"
                          checked={form.values.isActive}
                          onChange={e => form.setFieldValue('isActive', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="isAdmin">
                        <FormLabel>Admin</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="isAdmin"
                          checked={form.values.isAdmin}
                          onChange={e => form.setFieldValue('isAdmin', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="role">
                        <FormLabel>Rolle</FormLabel>
                        <InputGroup className="mb-3">
                          <FormSelect
                            name="role"
                            value={form.values.role?.id}
                            onChange={(option) => form.setFieldValue('role', listing && listing.roles && listing.roles.find(role => role.id === parseInt(option.target.value)))}
                          >
                            <option selected={form.values.role === null} disabled>keine</option>
                            {listing.roles && listing?.roles.map((role, key) => (
                              <option key={key} value={role.id}>{role.name}</option>
                            ))}
                          </FormSelect>
                          <Button variant="outline-secondary" onClick={() => form.setFieldValue('role', null)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
