import {gql, useMutation} from "@apollo/client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {Permissions} from "../auth/AuthContext";
import {ProtectedComponent} from "../auth/ProtectedComponent";

const MUT_PROOF_REMOVE = gql`
  mutation removeProof($id: Float!) {
    removeProof(id: $id) {
      name
    }
  }
`;

export const ProofRemove = (
  proof: {
    id: number;
  },
) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [removeProof] = useMutation(MUT_PROOF_REMOVE, {
    onCompleted: () => {
      toast.success("Druckfreigabe entfernt.");
    },
    onError: () => {
      toast.error("Fehler beim Entfernen der Druckfreigabe!");
    },
  });
  return (
    <>
      <ProtectedComponent permission={Permissions.proofDelete}>
        <Button className="me-2" variant="danger" onClick={handleShow}>
          <FontAwesomeIcon icon={faTrash} /> Entfernen
        </Button>
      </ProtectedComponent>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Druckfreigabe entfernen</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Druckfreigabe wirklich entfernen?
        </ModalBody>
        <ModalFooter>
          <Button className="me-2" variant="danger" onClick={() => removeProof({
            variables: { id: proof.id }
          })}>
            <FontAwesomeIcon icon={faTrash} /> Entfernen
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
