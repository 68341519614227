import React, {useState} from "react";
import {
  Button,
  Col, FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {faFloppyDisk, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MUT_ROLE_CREATE = gql`
  mutation createRole($createRoleInput: CreateRoleInput!) {
    createRole(createRoleInput: $createRoleInput) {
      id
    }
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  permissionProofCreate: Yup.boolean().required(),
  permissionProofUpdate: Yup.boolean().required(),
  permissionProofDelete: Yup.boolean().required(),
});

export const RoleCreate = (listing: {
  refetch: any;
}) => {
  const [createRole] = useMutation(MUT_ROLE_CREATE, {
    onCompleted: () => {
      toast.success("Rolle erstellt.");
      handleClose();
      listing.refetch();
    },
    onError: (e) => {
      console.log(e)
      toast.error("Fehler beim Erstellen der Rolle!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Rolle erstellen</ModalTitle>
        </ModalHeader>
        <Modal.Body>

          <Formik
            initialValues={{
              name: "",
              permissionProofCreate: false,
              permissionProofUpdate: false,
              permissionProofDelete: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              createRole({
                variables: { createRoleInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col>
                      <FormGroup className="mb-3" controlId="name">
                        <FormLabel>Name*</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          required
                          defaultValue={form.values.name}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <h5>Druckfreigabe:</h5>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofCreate">
                        <FormLabel>Erstellen</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofCreate"
                          checked={form.values.permissionProofCreate}
                          onChange={e => form.setFieldValue('permissionProofCreate', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofUpdate">
                        <FormLabel>Ändern</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofUpdate"
                          checked={form.values.permissionProofUpdate}
                          onChange={e => form.setFieldValue('permissionProofUpdate', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3" controlId="permissionProofDelete">
                        <FormLabel>Löschen</FormLabel>
                        <FormCheck
                          type="checkbox"
                          name="permissionProofDelete"
                          checked={form.values.permissionProofDelete}
                          onChange={e => form.setFieldValue('permissionProofDelete', e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
