import React, {useState} from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {UpdateUserPasswordInput} from "../../generated/graphql";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

const MUT_USER_UPDATE_PASSWORD = gql`
  mutation updateUserPassword($updateUserPasswordInput: UpdateUserPasswordInput!) {
    updateUserPassword(updateUserPasswordInput: $updateUserPasswordInput) {
      id
    }
  }
`;

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  password: Yup.string().required(),
});

export const UserUpdatePassword = (
  listing: {
    user: Partial<UpdateUserPasswordInput>;
    refetch: any;
  },
) => {

  const [updateUserPassword] = useMutation(MUT_USER_UPDATE_PASSWORD, {
    onCompleted: () => {
      toast.success("Benutzer Passwort bearbeitet.");
      handleClose();
      listing.refetch();
    },
    onError: (error) => {
      console.log(error)
      toast.error("Fehler beim Bearbeiten des Passwortes!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow} className="me-2" title="Passwort ändern">
        <FontAwesomeIcon icon={faKey} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Benutzer Passwort bearbeiten</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Formik
            initialValues={{
              id: listing.user.id,
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              updateUserPassword({
                variables: { updateUserPasswordInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>

                <div className="py-2">
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="password">
                        <FormLabel>Passwort*</FormLabel>
                        <FormControl
                          type="password"
                          name="password"
                          required
                          defaultValue={form.values.password}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}
