import React, {useContext} from "react";
import {
  Navigate,
  useLocation
} from 'react-router-dom';
import {PROOF_LIST} from "../../constants/routes";
import {AuthContext, JwtPayloadCuprint} from "./AuthContext";
import { jwtDecode } from "jwt-decode";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const {jwt} = useContext(AuthContext);
  const decoded: JwtPayloadCuprint = jwtDecode(jwt.toString());

  const location = useLocation();

  if (!decoded.isAdmin) {
    return <Navigate to={PROOF_LIST} replace state={{ from: location }} />;
  }

  return children;
};